import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Ul } from "../list/list";
import { TextContainer } from "../text-container/text-container";
import { Paragraph } from "./paragraph";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Paragraph",
	notepad: "https://hackmd.io/P2v7ncjsQ6myfWbVg19m_w",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Paragraph"
			subTitle="Use this component for blocks of text. In other words, one or more complete sentences, like this text."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Paragraph} />
				<InlineMessage variant="warning">
					<Paragraph>
						If your text is a single word or short snippet, use the{" "}
						<GatsbyLink to="../Inline text">inline text</GatsbyLink> component instead. Unless it
						functions as the title of a page or section, then use the heading component.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The paragraph component features various properties for customizing its appearance. Adjust
					the properties if it helps users complete their tasks. Specifically, by making the text's
					appearance match its message and importance. Don't make adjustments purely because of
					aesthetic reasons. The following sections showcase the properties and explain when to use
					them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The componenent's default appearance is best suited for neutral information of regular
					importance that does not need to be the first thing that a user notices.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Size</Header.H3>
				<Paragraph>
					Use this property to adjust how attention-grabbing the paragraph should be.
				</Paragraph>
				<Example fn={sizeExample} />
				<Header.H3>Tone</Header.H3>
				<Paragraph>
					Use this property to make the paragraph's color support its semantic message.
				</Paragraph>
				<Example fn={toneExample} />
				<Header.H3>Emphasis</Header.H3>
				<Paragraph>
					Use this property to make the paragraph's visual weight match the importance of its
					message.
				</Paragraph>
				<Example fn={emphasisExample} />
				<Header.H3>Alignment</Header.H3>
				<Paragraph>
					Use this property to center or right align the text in paragraphs. Use it sparingly and
					only for short paragraphs, since it negatively affects readability.
				</Paragraph>
				<Example fn={alignmentExample} />
				<Header.H3>Line height</Header.H3>
				<Paragraph>
					This property is by default set to multi-line, since paragraphs frequently wrap across
					multiple lines. In certain cases, it is certain that a paragraph will stay on a single
					line and the "extra" vertical height might be unwanted layout-wise. In these (rare) cases,
					the property can be set to single-line.
				</Paragraph>
				<Example fn={lineHeightExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Paragraph}
					initialProps={{
						children:
							"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
					}}
					exampleValues={{
						children: {
							"short text":
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
							"long text":
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use for blocks of text, which can be defined as one or more full sentences.",
							"Use the styling properties for functional, not aesthetic, purposes.",
							<>
								Wrap multiple paragraphs (with or without{" "}
								<GatsbyLink to="../Heading">headings</GatsbyLink>) in the{" "}
								<GatsbyLink to="../Text container">text container</GatsbyLink> component. This
								component applies correct spacing between typographic components.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The text is just a single word or short snippet. Use the{" "}
								<GatsbyLink to="../Inline text">inline text</GatsbyLink> component instead.
							</>,
							<>
								The text functions as the title of a page (section). Use the{" "}
								<GatsbyLink to="../Heading">heading</GatsbyLink> component instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Using the paragraph component for blocks of text makes that text more meaningful to
						assistive technologies, such as screenreaders. This provides assisitive technology users
						with a clear and structured overview of a page's content and makes it easier for them to
						navigate that content.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>Be direct &mdash; users scan pages for information instead of reading.</>,
							"Put the most important information first.",
							"Stick to one idea per sentence.",
							<>Keep sentences below 25 words &mdash; longer sentences are harder to comprehend.</>,
							"Frontload sentences with the important details.",
							<>
								Use <GatsbyLink to="../Heading">headings</GatsbyLink> to break up text and make it
								easier to scan for information.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H2>Notable Changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Paragraph>
					The name of this component was changed from P to Paragraph when it was moved from FancyPFG
					to FancyLib.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => <Paragraph>This is a default paragraph.</Paragraph>;

const sizeExample = () => (
	<>
		<Paragraph size="xSmall">The size of this paragraph is x-small.</Paragraph>
		<br />
		<Paragraph size="small">The size of this paragraph is small (the default).</Paragraph>
		<br />
		<Paragraph size="medium">The size of this paragraph is medium.</Paragraph>
		<br />
		<Paragraph size="large">The size of this paragraph is large.</Paragraph>
		<br />
		<Paragraph size="xLarge">The size of this paragraph is x-large.</Paragraph>
		<br />
		<Paragraph size="xxLarge">The size of this paragraph is xx-large.</Paragraph>
		<br />
		<Paragraph size="xxxLarge">The size of this paragraph is xxx-large.</Paragraph>
	</>
);

const toneExample = () => (
	<>
		<Paragraph tone="neutralDark">
			The tone of this paragraph is neutral-dark (the default). It can be used for neutral messages.
		</Paragraph>
		<br />
		<div style={{ backgroundColor: "#3C485E", height: 35, display: "flex", alignItems: "center" }}>
			<Paragraph tone="neutralLight">
				The tone of this paragraph is neutral-light. It can be used for neutral messages on dark
				backgrounds.
			</Paragraph>
		</div>
		<br />
		<Paragraph tone="positive">
			The tone of this paragraph is positive. It can be used for positive messages.
		</Paragraph>
		<br />
		<Paragraph tone="negative">
			The tone of this paragraph is negative. It can be used for negative messages.
		</Paragraph>
		<br />
		<Paragraph tone="warning">
			The tone of this paragraph is warning. It can be used for warning messages.
		</Paragraph>
		<br />
		<Paragraph tone="subtle">
			The tone of this paragraph is subtle. It can be used for neutral, but less important messages.
		</Paragraph>
	</>
);

const emphasisExample = () => (
	<>
		<Paragraph emphasis="normal">
			This paragraph has a normal emphasis (the default). It can be used for regular messages.
		</Paragraph>
		<br />
		<Paragraph emphasis="medium">
			This paragraph has a medium emphasis. It can be used for moderately important messages.
		</Paragraph>
		<br />
		<Paragraph emphasis="strong">
			This paragraph has a strong emphasis. It can be used for very important messages.
		</Paragraph>
	</>
);

const alignmentExample = () => (
	<>
		<Paragraph alignment="left">This paragraph is left aligned (the default).</Paragraph>
		<br />
		<Paragraph alignment="center">This paragraph is center aligned.</Paragraph>
		<br />
		<Paragraph alignment="right">This paragraph is right aligned.</Paragraph>
	</>
);

const lineHeightExample = () => (
	<>
		<Paragraph lineHeight="multi-line">
			This paragraph's line-height property is set to multi-line, which is the default value. As you
			can see, a line height that is larger than the font size prevents the lines from sticking
			together.
		</Paragraph>
		<br />
		<Paragraph lineHeight="single-line">
			The line-height property of this paragraph is set to single-line. As you can see, a line
			height that is equal to the font size results in lines that stick together, which negatively
			impacts the reading experience.
		</Paragraph>
	</>
);
